import {
  CalendarDate,
  DateValue,
  getLocalTimeZone,
  parseAbsoluteToLocal,
} from '@internationalized/date';
import {Fragment, memo} from 'react';
import {useDateFormatter} from './use-date-formatter';
import {shallowEqual} from '../utils/shallow-equal';
import {useSettings} from '../core/settings/use-settings';

export const DateFormatPresets: Record<string, Intl.DateTimeFormatOptions> = {
  numeric: {year: 'numeric', month: '2-digit', day: '2-digit'},
  short: {year: 'numeric', month: 'short', day: '2-digit'},
  long: {month: 'long', day: '2-digit', year: 'numeric'},
};

interface FormattedDateProps {
  date?: string | DateValue | Date;
  options?: Intl.DateTimeFormatOptions;
  preset?: keyof typeof DateFormatPresets;
}
export const FormattedDate = memo(
  ({date, options, preset}: FormattedDateProps) => {
    const {dates} = useSettings();
    const formatter = useDateFormatter(
      options || DateFormatPresets[preset || dates?.format]
    );

    if (!date) {
      return null;
    }

    if (date instanceof CalendarDate) {
      date = date.toDate(getLocalTimeZone());
    } else if (typeof date === 'string') {
      date = parseAbsoluteToLocal(date).toDate();
    }

    return <Fragment>{formatter.format(date as Date)}</Fragment>;
  },
  shallowEqual
);
