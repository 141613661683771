import React, {ReactNode} from 'react';
import clsx from 'clsx';
import {DialogSize} from './dialog';

interface DialogFooterProps {
  children: ReactNode;
  startAction?: ReactNode;
  className?: string;
  dividerTop?: boolean;
  size?: DialogSize;
}
export function DialogFooter({
  children,
  startAction,
  className,
  dividerTop,
  size,
}: DialogFooterProps) {
  return (
    <div
      className={clsx(
        className,
        dividerTop && 'border-t',
        getPadding(size),
        'flex items-center gap-10 flex-shrink-0'
      )}
    >
      <div>{startAction}</div>
      <div className="ml-auto flex items-center gap-10">{children}</div>
    </div>
  );
}

function getPadding(size?: DialogSize) {
  switch (size) {
    case 'xs':
      return 'p-14';
    case 'sm':
      return 'p-18';
    default:
      return 'px-24 py-20';
  }
}
