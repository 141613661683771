import {ButtonVariant} from './get-shared-button-style';

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | null;

interface Props {
  padding?: string;
  equalWidth?: boolean;
  variant?: ButtonVariant;
}

export function getButtonSizeStyle(
  size?: ButtonSize,
  {padding, equalWidth, variant}: Props = {}
): string {
  switch (size) {
    case 'xs':
      if (variant === 'link') return 'text-xs';
      return `text-xs h-30 ${equalWidth ? 'w-30' : padding || 'px-14'}`;
    case 'sm':
      if (variant === 'link') return 'text-sm';
      return `text-sm h-36 ${equalWidth ? 'w-36' : padding || 'px-18'}`;
    case 'md':
      if (variant === 'link') return 'text-base';
      return `text-base h-42 ${equalWidth ? 'w-42' : padding || 'px-22'}`;
    case 'lg':
      if (variant === 'link') return 'text-lg';
      return `text-base h-50 ${equalWidth ? 'w-50' : padding || 'px-26'}`;
    case 'xl':
      if (variant === 'link') return 'text-xl';
      return `text-lg h-60 ${equalWidth ? 'w-60' : padding || 'px-32'}`;
    default:
      return size || '';
  }
}

export function getIconSizeStyle(size?: ButtonSize): string {
  switch (size) {
    case 'xs':
      return 'icon-xs';
    case 'sm':
      return 'icon-sm';
    case 'md':
      return 'icon-sm';
    case 'lg':
      return 'icon-lg';
    case 'xl':
      return 'icon-xl';
    default:
      // cant' return "size" variable here, append in field will not work with it
      return '';
  }
}
